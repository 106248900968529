<template>
  <div id="browserExtensions">
    <input
      type="text"
      :style="{ position: 'absolute', top: '-999999px', left: '-999999px' }"
      id="inputText"
    />
    <v-snackbar :color="color" v-model="snackbar" top right>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-container fluid>
      <div class="title-header">
        <h2 class="mr-4">常用</h2>
        <v-checkbox
          v-model="selectedUp"
          label="全选"
          @change="selectedAll(true)"
        ></v-checkbox>
      </div>
      <v-row>
        <v-col
          sm="6" md="4" cols="12"
          v-for="(item, index) in extensions"
          :key="index"
          v-if="item.is_up"
        >
          <div class="checkbox-group">
            <img
              :src="item.img"
              width="50"
              :style="{ display: 'inline-block' }"
              alt=""
            />
            <v-checkbox
              v-model="selected"
              :label="item.name"
              :value="item.url"
              hide-details
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>
      <div class="title-header mt-8 mb-4">
        <h2 class="mr-4">其他</h2>
      </div>
      <v-row>
        <v-col
          sm="6" md="4" cols="12"
          v-for="(item, index) in extensions"
          :key="index"
          v-if="!item.is_up"
        >
          <div class="checkbox-group">
            <img
              :src="item.img"
              width="50"
              :style="{ display: 'inline-block' }"
              alt=""
            />
            <v-checkbox
              v-model="selected"
              :label="item.name"
              :value="item.url"
              hide-details
              class="text-center"
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>
      <!-- <hr class="my-8" /> -->
      <v-row>
        <v-col sm="6" md="4" cols="12"
          ><v-btn color="primary" outlined large block @click="copyMacCmd()"
            >生成并复制Mac终端命令</v-btn
          ></v-col
        >
        <v-col sm="6" md="4" cols="12"
          ><v-btn color="primary" outlined large block @click="copyWinCmd()"
            >生成并复制Win终端命令</v-btn
          ></v-col
        >
        <v-col sm="6" md="4" cols="12"
          ><v-btn color="light-blue" outlined dark large block v-on:click="openUrls()"
            >批量打开所选插件</v-btn
          ></v-col
        >
        <!-- <v-col sm="6" md="4" cols="12"
          ><v-btn color="info" outlined large block @click="downloadWin()"
            >生成并下载Mac脚本</v-btn
          ></v-col
        >
        <v-col sm="6" md="4" cols="12"
          ><v-btn color="info" outlined large block @click="downloadWin()"
            >生成并下载Win批处理</v-btn
          ></v-col
        > -->
      </v-row>
      <h4 class="mt-8">
        Note: 批量打开所选插件 默认浏览器禁止多窗口同时打开，需要浏览器允许授权
      </h4>
      <p>
        操作方法：选择2个以上插件，然后点击“打开所选插件”，默认情况下只打开一个窗口，先把打开的窗口关闭，回到当前页面，浏览器右上角（保存书签按钮的左边）会提示Pop-ups
        and redirects授权提示，点击并选择Always allow
        ...，再次点击“打开所选插件就正常了”
      </p>
      <img src="@/assets/pop.png" height="300" alt="" />
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectedUp: false,
    selected: [],
    extensions: [],
    snackbar: false,
    text: ``,
    color: "",
  }),
  computed: {
    getUpAll() {
      return this.extensions.filter((item) => item.is_up);
    },
  },
  methods: {
    selectedAll(isDefault) {
      if (this.selectedUp) {
        this.getUpAll.map((item) => this.selected.push(item.url));
        return;
      }
      let tmpArr = this.getUpAll.map((item) => item.url);
      this.selected = this.selected.filter((url) => !tmpArr.includes(url));
    },
    openUrls() {
      if (this.selected.length) {
        this.selected.map((url) => window.open(url));
      } else {
        this.text = "请选择至少1项";
        this.color = "error";
        this.snackbar = true;
      }
    },
    copyWinCmd() {
      if (this.selected.length) {
        let str = "start chrome ";
        this.selected.map((url, index) => {
          if (
            this.selected.length === 1 ||
            this.selected.length === index + 1
          ) {
            str += url;
          } else {
            str += url + " ";
          }
        });

        this.copied(str);
      } else {
        this.text = "请选择至少1项";
        this.color = "error";
        this.snackbar = true;
      }
    },
    copied(str = "") {
      if (!str) return;
      let inputText = document.getElementById("inputText");
      inputText.value = str;
      inputText.select();
      document.execCommand("copy");

      this.text = "复制成功";
      this.color = "success";
      this.snackbar = true;
    },
    copyMacCmd() {
      if (this.selected.length) {
        let str = "open ";
        this.selected.map((url, index) => {
          if (
            this.selected.length === 1 ||
            this.selected.length === index + 1
          ) {
            str += url;
          } else {
            str += url + " ";
          }
        });

        this.copied(str);
      } else {
        this.text = "请选择至少1项";
        this.color = "error";
        this.snackbar = true;
      }
    },
    downloadWin() {
      this.text = "暂不支持";
      this.color = "error";
      this.snackbar = true;
      return;
      if (!this.selected.length) {
        this.text = "请选择至少1项";
        this.color = "error";
        this.snackbar = true;
        return;
      }
      let str = `
echo
color 3f

      `;
      this.selected.map((url, index) => {
        if (this.selected.length === 1 || this.selected.length === index + 1) {
          str += `start "C:\Program Files (x86)\Google\Chrome\Application\google.exe" ${url}`;
        } else {
          str += `start "C:\Program Files (x86)\Google\Chrome\Application\google.exe" ${url}\n`;
        }
      });
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(str)
      );
      element.setAttribute("download", "Chrome插件-v1.0.bat");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
  async mounted() {
    let result = await this.$axios.get("/api/extension-links/");
    if (result.data.length) {
      this.extensions = result.data;

      this.extensions.map(
        (item) => item.is_default && this.selected.push(item.url)
      );
    }
  },
};
</script>

<style scoped>
.title-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-group {
  text-align: center;
}
</style>